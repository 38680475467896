import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import MyForm from "./MyForm";
import { formatDate } from "../utils/common";

export const PumpInfo = ({ data, instituteOptions, serviceOptions, usuarios, setSearched, handleSubmit,obtenerInventarios,setServiceOptions}) => {
    const [showForm, setShowForm] = useState(false)
    const responsable = usuarios.find(user => user.id === data.responsable_id);
    const responsableName = responsable? responsable.usuario:""
    const newData = { ...data, id: "", fecha_actualizacion: formatDate(data.fecha_actualizacion) !== "30-11-1899" ? formatDate(data.fecha_actualizacion,true): "" ,responsable:responsableName,responsable_id:"" };
    const nonEmptyProperties = Object.keys(newData).filter(key => newData[key] !== '' &&  key !== "agregado" );

    return (
        !showForm ? (
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    alignItems: "flex-start", // Alinea los elementos hijos verticalmente al centro
                    justifyContent: "center",
                    gap: ".5rem",
                    padding: "1rem",
                }}
            >
                {nonEmptyProperties.map((property, index) => (
                    <Typography key={index} >
                        <strong>{property.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}:</strong> {newData[property]}
                    </Typography>
                ))}

                <Box
                    display="flex"
                    justifyContent="end"
                    sx={{
                        width: "100%"
                    }}
                >
                    <Button onClick={(e) => setShowForm(true)} variant="contained" color="primary">
                        Editar
                    </Button>
                </Box>

            </Box>
        ) : (
            <>
                <MyForm
                    data={data}
                    serviceOptions={serviceOptions}
                    setServiceOptions = {setServiceOptions}
                    instituteOptions={instituteOptions}
                    setSearched={setSearched}
                    setShowForm1={setShowForm}
                    handleSubmit={handleSubmit}
                    obtenerInventarios={obtenerInventarios}
                     />
            </>

        )
    );
};
