import React, { useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { AttachFile, Cancel, FileDownload, Update } from '@mui/icons-material';
import { downloadRequiredFormat, formatDate, setUrl } from '../utils/common';
import { ListChips } from './ListChips';
import { Constants } from '../utils/Constants';
import { Colors } from '../utils/Colors';
import { MyLoading } from './MyLoading';

export const UploadFileComponent = ({ currentUser, irArriba, setSerie, obtenerInventarios, setShowUploadExcel }) => {
  const [data, setData] = useState([]);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [seriesUploadSuccess, setSeriesUploadSuccess] = useState([]);
  const [seriesUploadFailed, setSeriesUploadFailed] = useState([]);
  const [countUpdated, setCountUpdated] = useState(0)
  const [areValidHeaders, setAreValidHeaders] = useState(true)
  const [step, setStep] = useState("uploadFile")
  const [fileIsNull, setFileIsNull] = useState(false)

  const handleFileChange = (event) => {
    setFileIsNull(false)
    setStep("processingFile")
    setAreValidHeaders(true)
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const dataArray = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(dataArray, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      if (json.length === 0) {
        setFileIsNull(true)
        setStep("uploadFile")
        return
      }

      const necessaryHeaders = Object.keys(json[0])
      const validHeaders = Constants.validHeaders

      const filterHeaders = validHeaders.every(value => necessaryHeaders.includes(value))
      setAreValidHeaders(filterHeaders)

      if (filterHeaders) {
        setStep("updateInventory")
        setData(json);
        setFileName(selectedFile.name);

      } else {
        setStep("uploadFile")
      }

    };

    reader.readAsArrayBuffer(selectedFile);
  };

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleUpdateInventories = async () => {
    setStep("processingUpdate")

    const newData = data.map(dato => ({
      serie: dato.serie,
      qr: dato.qr,
      modelo: dato.modelo,
      tipo: dato.tipo,
      servicio: dato.servicio,
      fecha_actualizacion: formatDate(undefined, true),
      responsable_id: currentUser.id
    }));

    const results = await Promise.all(newData.map(async (nd) => {
      try {
        const { ok, affectedRows } = await updateInventory(nd);

        return { serie: nd.serie, ok, affectedRows };
      } catch (err) {
        console.error('Error updating inventory:', err);
        return { serie: nd.serie, ok: false, affectedRows: 0 };
      }
    }));

    const successfulSeries = results.filter(result => result.ok && result.affectedRows > 0).map(result => result.serie);
    const failedSeries = results.filter(result => !result.ok || result.affectedRows === 0).map(result => result.serie);

    setSeriesUploadSuccess(successfulSeries);
    setSeriesUploadFailed(failedSeries);
    setStep("resultUpdated")
  };

  const updateInventory = async (inventory) => {
    setCountUpdated(countUpdated + 1)
    try {
      const response = await fetch(setUrl('inventario'), {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(inventory),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseJson = await response.json()

      const returnData = { affectedRows: responseJson.affectedRows, ok: response.ok }

      return returnData;

    } catch (err) {
      console.error(err);
      return { ok: false, affectedRows: 0 };
    }
  };

  const handleDownloadExcel = (e) => {
    console.log("descargando excel")
    downloadRequiredFormat(currentUser.usuario)
  }

  return (
    <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '1rem', gap: '1rem' }}>
      {step === 'uploadFile' && (
        <Box display="flex" flexDirection="column" gap="0.5rem" sx={{ width: '100%' }}>
          {!areValidHeaders ? (
            <Box display="flex" justifyContent="flex-start" alignItems="center"  gap = ".5rem">
              <Typography variant='subtitle2' color="error">Formato no valido, descargue el formato requerido</Typography>
              <IconButton
                onClick={handleDownloadExcel}
                size='small'
                sx={{
                  color: Colors.primary,
                  "&:hover": {
                    backgroundColor: Colors.primaryBack,
                  },
                }}
              >
                <FileDownload />
              </IconButton>
            </Box>
          ) : (
            fileIsNull ? (
              <Typography variant='subtitle2' color="error">El archivo esta vacio, seleccione otro</Typography>
            ) : (
              <Box display="flex" justifyContent="flex-start" alignItems="center" gap = ".5rem">
                <Typography variant='subtitle2'>Descargue el formato requerido</Typography>
                <IconButton
                  onClick={handleDownloadExcel}
                  size='small'
                  sx={{
                    color: Colors.primary,
                    "&:hover": {
                      backgroundColor: Colors.primaryBack,
                    },
                  }}
                >
                  <FileDownload />
                </IconButton>
              </Box>
            )
          )
          }
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="1rem" sx={{ width: '100%' }}>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <Button variant="contained" color="success" onClick={handleCustomButtonClick} startIcon={<AttachFile />}>
              Archivo
            </Button>
            <Button variant="contained" color="primary" onClick={() => setShowUploadExcel(false)} startIcon={<Cancel />}>
              Cancelar
            </Button>
          </Box>
        </Box>
      )}
      {step === 'processingFile' && <MyLoading message="Procesando archivo" />}
      {step === 'updateInventory' && (
        <Box display="flex" flexDirection="column" gap=".5rem" sx={{ width: '100%' }}>
          <Typography>{fileName}</Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
            <Button variant="contained" color="warning" onClick={handleUpdateInventories} startIcon={<Update />} sx={{ flexBasis: "45%" }}>
              Actualizar
            </Button>
            <Button variant="contained" onClick={(e) => setStep("uploadFile")} startIcon={<Cancel />} sx={{ flexBasis: "45%" }} >
              Cancelar
            </Button>
          </Box>
        </Box>
      )}
      {step === 'processingUpdate' && <MyLoading message="Actualizando registros" />}
      {step === 'resultUpdated' && (
        <Box display="flex" flexDirection="column" gap="1rem" sx={{ width: '100%' }}>
          <Typography variant='h6'>Resumen</Typography>
          <Typography><strong>Actualizaciones exitosas:</strong> {seriesUploadSuccess.length}</Typography>
          <Typography><strong>Actualizaciones fallidas:</strong> {seriesUploadFailed.length}</Typography>
          {seriesUploadFailed.length > 0 && (
            <Box display="flex" flexDirection="column" gap="1rem" sx={{ width: '100%' }}>
              <Typography>Las siguientes bombas deben ser inventariadas manualmente:</Typography>
              <ListChips seriesUploadFailed={seriesUploadFailed} irArriba={irArriba} setSerie={setSerie} />
            </Box>
          )}
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              onClick={() => {
                setStep("uploadFile");
                setFileName("");
                obtenerInventarios(currentUser.id);
              }}
              variant='contained'
            >
              Aceptar
            </Button>
          </Box>
        </Box>
      )}
    </Paper>
  );
};
