import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Autocomplete, Box, CircularProgress, Modal, Paper, Typography } from '@mui/material';
import { Add, Cancel, CheckCircle } from '@mui/icons-material';
import { addServices, fetchLastService, formatDate, setUrl } from '../utils/common';
import MyInputV2 from './MyInputV2';
import Error from './Error';

const AddInventory = ({ serie, instituciones, servicios, setServicios, currentUser, setIsAdd, obtenerInventarios }) => {
  const [formData, setFormData] = useState({
    qr: '',
    modelo: null,
    tipo: null,
    institucion: null,
    servicio: null
  });
  const [exito, setExito] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(true)
  const [showErrorHelper, setShowErrorHelper] = useState(false)
  const [newSerie, setNewSerie] = useState(serie)
  const [isAutocompleted, setIsAutocompleted] = useState(false)
  const [lastService, setLastService] = useState(null)
  const [newService, setNewService] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const optionsList = servicios ? servicios.map(sop => sop.servicio) : []



  const tipos = useMemo(() => [
    { id: 1, tipo: "INFUSOR" },
    { id: 2, tipo: "PERFUSOR" },
    { id: 3, tipo: "NO APLICA" }
  ], []);

  const modelos = useMemo(() => [
    { id: 1, modelo: "SPACE" },
    { id: 2, modelo: "SPACE PLUS" },
    { id: 3, modelo: "COMPACT" },
    { id: 4, modelo: "COMPACT PLUS" },
    { id: 5, modelo: "ENTEROPORT PLUS" },
    { id: 6, modelo: "FMS" },
    { id: 7, modelo: "FM" }
  ], []);

  useEffect(() => {
    const lastServiceObject = fetchLastService();
    setLastService(lastServiceObject);
    setFormData(prevState => ({
      ...prevState,
      servicio: lastServiceObject
    }))

    setIsAutocompleted(true)

  }, [])


  const sendData = async (dataToSend) => {
    try {
      const response = await fetch(setUrl("inventario"), {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify(dataToSend)
      });

      const responseData = await response.json();

      if (response.status === 200) {
        setExito(true);
      } else {
        setExito(false);
      }

      setLoading(false);
      setShowForm(false);
      console.log(responseData);

      // Aquí usamos await dentro del bloque async para asegurarnos de que se maneje correctamente
      let newServiceAdded = formData.servicio

      if (!optionsList.includes(newService.trim())) {
        newServiceAdded = await addServices(newService.trim());
        setServicios(prevState => [...prevState, newServiceAdded]);
        console.log("Agregando nuevo servicio");
      }

      localStorage.setItem("lastservice", JSON.stringify(newServiceAdded));

    } catch (err) {
      console.log(err);
      setLoading(false);
      setExito(false); // Puedes configurar esto según tus necesidades
    }
  }

  const checkDeviceExistence = (e) =>{
    e.preventDefault();
    setLoading(true);
    const dataToVerify = {
      "serie": newSerie,
      "modelo": formData.modelo.modelo,
      "tipo": formData.tipo.tipo
    };
    
    fetch(setUrl("verificar"), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToVerify)
    })
    .then(response => response.json())
    .then(data => {
      setLoading(false)
      if(data.length>0){
        setErrorMessage("La bomba ya esta registrada, cancele y edite sus propiedades si es necesario")
      }else{
        setErrorMessage("")
        handleAddData()
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoading(false)
      setExito(false)
    });
  }

  const handleAddData = async () => {
    setLoading(true);
    const dataToSend = {
      "serie": newSerie,
      "qr": formData.qr,
      "modelo": formData.modelo.modelo,
      "tipo": formData.tipo.tipo,
      "institucion": formData.institucion.institucion,
      "servicio": newService.trim(),
      "fecha_actualizacion": formatDate(undefined, true),
      "responsable_id": currentUser.id,
      "agregado": 1
    };


    try {
      const response = await fetch(setUrl(`inventario/qr/${dataToSend.qr}`));
      const inventarioByQr = await response.json();
      console.log(inventarioByQr.length)

      if (inventarioByQr.length > 0) {
        setShowErrorHelper(true)
        setLoading(false)
      } else {
        sendData(dataToSend)
      }


    } catch (error) {
      console.error("Error al obtener los mantenimientos:", error);
      setLoading(false)
      setExito(false)
    }
  };

  return (
    <Paper elevation={3} sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", padding: "1rem" }}>
        {
          showForm ? (
            <>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>Agregando {newSerie}</Typography>
              {
                  errorMessage !== "" && (
                    <Typography color="error" sx={{padding:"1rem 0"}}>{errorMessage}</Typography>
                  )
                }
              <form onSubmit={checkDeviceExistence} style={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
                <MyInputV2
                  error={showErrorHelper}
                  helperText='El Qr ya esta en uso'
                  label={"Qr"}
                  value={formData.qr}
                  setValue={(e) => setFormData((prevData) => ({
                    ...prevData,
                    qr: ""
                  }))}
                  onChange={(event) => {
                    const newValue = event.target.value
                    setFormData((prevData) => ({
                      ...prevData,
                      qr: newValue ? newValue : ""
                    }));
                  }}
                />
                <Autocomplete
                  id="modelo"
                  size="small"
                  options={modelos}
                  getOptionLabel={(option) => option.modelo}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={formData.modelo}
                  onChange={(event, newValue) => {
                    if (newValue.modelo === "COMPACT") {
                      setNewSerie("C" + serie)
                      console.log("La bomba es compact")
                    } else {
                      setNewSerie(serie)
                    }
                    setFormData((prevData) => ({
                      ...prevData,
                      modelo: newValue ? newValue : null
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Modelo" fullWidth required />}
                />

                <Autocomplete
                  id="tipo"
                  size="small"
                  options={tipos}
                  getOptionLabel={(option) => option.tipo}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={formData.tipo}
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      tipo: newValue ? newValue : null
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Tipo" fullWidth required />}
                />

                <Autocomplete
                  id="institucion"
                  size="small"
                  options={instituciones}
                  getOptionLabel={(option) => option.institucion || ''}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={formData.institucion}
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      institucion: newValue ? newValue : null
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Institucion" fullWidth required />}
                />


                <Autocomplete
                  freeSolo
                  id="servicios"
                  size="small"
                  options={servicios}
                  getOptionLabel={(option) => option.servicio || ""}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={formData.servicio}
                  onInputChange={(_, newValue) => {
                    if (lastService !== null) {
                      if (newValue !== lastService.servicio) {
                        setIsAutocompleted(false)
                      }
                    }
                    setNewService(newValue)
                  }}

                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      servicio: newValue ? newValue : null
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Servicio" error={isAutocompleted} helperText={isAutocompleted ? "Autocompletado del ultimo registro, cambiar si es necesario" : ""} fullWidth required />}
                />


                <Box
                  display="flex" justifyContent="space-between" justifyItems="center">
                  <Button
                  disabled = {errorMessage !== ""}
                    type="submit"
                    variant="contained"
                    sx={{ fontWeight: "bold", flexBasis: "45%" }}
                    startIcon={<Add />}
                  >
                    Agregar
                  </Button>
                  <Button
                    variant="contained"
                    color='error'
                    sx={{ fontWeight: "bold", flexBasis: "45%" }}
                    onClick={e => setIsAdd(false)}
                    startIcon={<Cancel />}
                  >
                    Cancelar
                  </Button>
                </Box>
                

                {loading && (
                  <Modal
                    open={loading}
                    onClose={() => setLoading(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box display="flex" sx={{ height: "100vh", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                      <CircularProgress />
                      <Typography id="modal-modal-description" sx={{ mt: 2, color: "white" }}>
                        <strong>Agregando un nuevo registro</strong>
                      </Typography>
                    </Box>
                  </Modal>
                )}

              </form>
            </>
          ) : (
            exito ? (
              <>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <Typography variant="h6" gutterBottom>
                    Dispositivo registrado con éxito
                  </Typography>
                  <CheckCircle color="primary" style={{ fontSize: 48 }} />
                  <Box display="flex" justifyContent="center" mt={2}>
                    <Button variant="contained" color="primary" style={{ marginRight: '1rem' }} onClick={(e) => { setIsAdd(false); obtenerInventarios(currentUser.id) }}>
                      Aceptar
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (< Error setShowForm={setShowForm} />)
          )
        }
      </Box>
    </Paper>
  );
};

export default AddInventory
