import { CheckCircle } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";


const Exito = ({setSearched,handleSubmit,obtenerInventarios, currentUser,update = true }) => {
    const handleRegister = (event) => {
        setSearched(false);  
        handleSubmit(event);
        obtenerInventarios(currentUser.id)
      }
    return (

        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="h6" gutterBottom>
                Dispositivo {update ?"actualizado": "registrado"} con éxito
            </Typography>
            <CheckCircle color="primary" style={{ fontSize: 48 }} />
            <Box display="flex" justifyContent="center" mt={2}>
                <Button variant="contained" color="primary" style={{ marginRight: '1rem' }} onClick={handleRegister}>
                    Aceptar
                </Button>
            </Box>
        </Box>
    );
};

export default Exito;