import { Box, IconButton, Paper, Typography } from "@mui/material"
import { Colors } from "../../utils/Colors"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useMemo, useState } from "react"
import { handleDownloadExcel, organizarDatosPorCategoria } from "../../utils/common";
import { Download } from "@mui/icons-material";

export const MyItemN1 = ({ fecha, inventarios, handleAutoSearch, user }) => {
    const [showSeries, setShowSeries] = useState(false)

    const getTypePump = (inventario) => {
        const inventarioList = inventario.modelo.split(" ")
        let value

        if (inventarioList.length > 1) {
            value = inventario.tipo.charAt(0) + inventarioList[0].charAt(0) + inventarioList[1].charAt(0)

        } else {
            value = inventario.tipo.charAt(0) + inventarioList[0].charAt(0)
        }


        return value
    }

    const totalByTipo = useMemo(() => {
        const newsInventarios = inventarios.map(inv => ({ ...inv, tipo_abreviado: getTypePump(inv) }))
        const ninvBynt = organizarDatosPorCategoria(newsInventarios, "tipo_abreviado")
        const result = Object.keys(ninvBynt)
            .map(key => `${ninvBynt[key].length} ${key}`)
            .join(' - ');

        return result

    }, [inventarios])

    const copiarContenido = async (texto) => {
        try {
            await navigator.clipboard.writeText(texto);
            console.log('Contenido copiado al portapapeles');
        } catch (err) {
            console.error('Error al copiar: ', err);
        }
    };

    const handleCopy = (serie) => {
        handleAutoSearch(serie);
        copiarContenido(serie);
    }



    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                gap: '.5rem',
                padding: '0rem',
                borderRadius: '4px',
                width: '95%',
                boxSizing: 'border-box',
                border: '2px solid ' + Colors.primary,
            }}

        >

            <Box
                display="flex"
                gap="1rem"
                sx={{
                    width: "100%",
                    padding: "0.3rem 0.5rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: Colors.primaryBack,
                    borderRadius: showSeries ? "4px 4px 0 0" : "4px",
                    borderBottom: showSeries ? `2px solid ${Colors.primary}` : "none"
                }}
            >

                <Typography variant="subtitle2" ><strong>{fecha}</strong></Typography>

                <Paper
                    display="flex"
                    elevation={3}
                    title={
                        !showSeries ? (
                            "Ver mas"
                        ) : (
                            "Ver menos"
                        )
                    }
                    onClick={(e) => setShowSeries(!showSeries)}
                    sx={{
                        color: "white",
                        background: Colors.primary,
                        padding: "0.2rem 0.5rem",
                        cursor: "pointer",
                    }}
                >
                    <Typography variant="subtitle2">
                        {inventarios.length}
                    </Typography>

                </Paper>
            </Box>
            {
                showSeries && (
                    <>
                        <Box
                            display="flex"
                            padding="0.2rem .5rem"
                            sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "1px solid #ccc"
                            }}
                        >
                            <Typography variant="subtitle2"><strong>Total:</strong> {totalByTipo} </Typography>
                            <IconButton
                                title="Descargar Excel"
                                onClick={(e) => handleDownloadExcel(inventarios.map((dato) => ({ ...dato, responsable: user.usuario })),fecha)}
                                sx={{
                                    color: Colors.primary
                                }}
                            >
                                <Download />
                            </IconButton>
                        </Box>
                        {
                            inventarios.map((inventario, index) => (
                                <Box
                                    key={index}
                                    display="flex"
                                    padding="0.2rem .5rem"
                                    sx={{
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: index !== inventarios.length - 1 ? "1px solid #ccc" : "none"
                                    }}
                                >
                                    <Typography variant="subtitle2">{inventario.serie}</Typography>
                                    <Typography variant="subtitle2">{getTypePump(inventario)}</Typography>
                                    <IconButton
                                        title="Copiar serie"
                                        onClick={(e) => { handleCopy(inventario.serie) }}
                                        sx={{
                                            color: inventario.agregado ? "red" : Colors.primary
                                        }}
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>

                                </Box>
                            ))
                        }
                    </>
                )
            }
        </Box>
    )
}